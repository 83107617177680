window.Egotter = {};

function extractErrorMessage(xhr, textStatus, errorThrown) {
  var message;
  try {
    if (xhr.responseText) {
      message = JSON.parse(xhr.responseText)['message'];
    }
  } catch (e) {
    logger.warn('Parsing xhr.responseText failed', xhr.responseText, e);
  }
  if (!message) {
    if (errorThrown === 'Forbidden') {
      message = '一時的なエラーが起きました。ページを再読込してください。' + '(' + errorThrown + ')';
    } else if (errorThrown === 'Request Timeout') {
      message = '一時的なエラーが起きました。ページを再読込してください。' + '(' + errorThrown + ')';
    } else if (errorThrown === 'Internal Server Error') {
      message = '一時的なエラーが起きました。ページを再読込してください。' + '(' + errorThrown + ')';
    } else {
      message = xhr.status + ' (' + errorThrown + ')';
      logger.warn('Set default error message', message);
    }
  }
  return message;
}

window.extractErrorMessage = extractErrorMessage;

function showErrorMessage(xhr, textStatus, errorThrown) {
  var message = extractErrorMessage(xhr, textStatus, errorThrown);
  ToastMessage.warn(message);
  return message;
}

window.showErrorMessage = showErrorMessage;
